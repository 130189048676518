import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ThemePalette } from '@angular/material/core';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent {

  @Input() label: string | undefined;
  @Input() destructive = false;
  @Input() disabled = false;
  @Input() link = false;

  @Input() color: ThemePalette = 'primary';
  @Input() secondary = false;

  @Input() icon: string | undefined;

  @Input() routerLink: string | undefined;
  @Input() href: string | undefined;
  @Input() target = '_blank';
  @Input() tooltip?: string;

  @Input() width = 'auto';

  @Output() clicked = new EventEmitter<MouseEvent>();

  click($event: MouseEvent) {
    this.clicked.emit($event);
  }
}
