import { baseEnvironment, Environment } from './environment.base';

export const environment: Environment = {
  ...baseEnvironment,

  env: 'stage',

  api: {
    host: 'https://api.stage.b2b.rvprintfactory.com',
    integration: 'https://stage.api.rvprintfactory.com',
  },

  apiKey: {
    GOOGLE_API_KEY: 'AIzaSyDePPZMgen7-MFoJc74K-PbwU7z_sVDDTo',
  },

  auth: {
    region: 'eu-central-1',
    pool_id: 'eu-central-1_LPA0sm9gw',
    client_id: '1krufe4ndsjls17b7j7gsk7v7'
  },
};
